<template>
  <div>
    <b-modal id="leader-report-modal" hide-footer @hide="closeModal">
      <template v-slot:modal-header="{ close }">
        <h6 class="mb-0">
          {{ $t("LEADERS.REPORT") }}
        </h6>
        <b-icon class="close-modal-icon" icon="x" @click="close"></b-icon>
      </template>
      <div :class="$i18n.locale == 'en' ? '' : 'rtl'">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(onSubmit)" a-tag="formReportLeader">
            <div class="row">
              <b-form-group class="col-lg-12">
                <TextAreaField
                  v-model="form.reason"
                  rows="8"
                  no-resize
                  rules="required"
                  :label="$t('LEADERS.REASON')"
                  :name="$t('LEADERS.REASON')"
                />
              </b-form-group>
            </div>
            <b-form-group class="d-flex justify-content-end">
              <div class="d-inline-block mr-3">
                <Button
                  type="button"
                  color="light-dark"
                  customClass="text-uppercase "
                  @click="$bvModal.hide('leader-report-modal')"
                >
                  {{ $t("GLOBAL_CANCEL") }}
                </Button>
              </div>
              <Button type="submit" color="primary" :loading="loading" customClass="text-uppercase">
                {{ $t("GLOBAL_SEND") }}
              </Button>
            </b-form-group>
          </b-form>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        reason: null,
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
      this.$refs.observer.reset();
      this.form = {
        reason: null,
      };
    },
    onSubmit() {
      this.$emit("handleReportLeader", this.form.reason);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
