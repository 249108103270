<template>
  <div>
    <h4 class="page-title">{{ $t("LEADERS.PROFILE") }}</h4>

    <div class="text-center p-3" v-if="loading">
      <b-spinner variant="primary" label="Loading..."></b-spinner>
    </div>

    <div v-else>
      <div class="main-card mb-4" a-tag="leaderProfile">
        <leader-profile
          :leader="leader"
          :loading="followLoading"
          @handleSelectedLeader="handleSelectedLeader"
          @handleUnfollow="handleUnfollow"
          @handleOpenReportModal="handleOpenReportModal"
        />

        <!-- follow leader -->
        <leader-follow-modal
          :leader="selectedLeader"
          :loading="followLoading"
          @handleFollow="handleFollow"
          @closeModal="handleCloseFollowModal"
        />
        <!-- report leader -->
        <leader-report-modal :loading="reportLoading" @handleReportLeader="handleReportLeader" />
      </div>
      <div class="custom-navbar-container" a-tag="leaderTabs">
        <b-tabs class="custom-navbar">
          <b-tab
            v-for="(tab, index) in tabs"
            :key="index"
            :title="tab.name"
            :active="selectedTab === tab.link"
            @click="handleSelectTab(tab.link)"
          >
          </b-tab>
        </b-tabs>
      </div>
      <div
        class="main-card mb-4"
        :class="{
          'posts-main-card': selectedTab === 'posts',
        }"
      >
        <!-- portflio -->
        <div v-if="selectedTab === 'portflio'" a-tag="leaderPortflio">
          <line-chart v-if="portflio" :chartdata="portflio" :options="options" />
        </div>

        <!-- history -->
        <empty v-if="selectedTab === 'history'" :title="$t('LEADERS.NO_HISTORY')" />

        <!-- post -->
        <div v-if="selectedTab === 'posts'" a-tag="leaderPosts">
          <div
            class="text-right mb-4"
            v-if="
              user &&
              user.is_leader &&
              leader.user &&
              user.id === leader.user.data.id &&
              !showAllComments
            "
          >
            <Button @click="handleOpenAddPostModal">
              {{ $t("LEADERS.ADD_POST") }}
            </Button>
          </div>
          <template v-if="leader.posts.data.length">
            <!-- show post and all comments  -->
            <div v-if="showAllComments && showAllComments.show">
              <post-and-all-comments-card
                :post="showAllComments.post"
                @handleOpenAddCommentModal="handleOpenAddCommentModal"
                @handleBack="showAllComments = null"
                @handleSelectEmoji="handleSelectEmoji"
              />
            </div>
            <!-- show posts only  -->
            <div v-else>
              <div v-for="(post, index) in leader.posts.data" :key="index" class="posts-container">
                <post-card
                  :post="post"
                  @handleOpenAddCommentModal="handleOpenAddCommentModal"
                  @handleShowAllComments="handleShowAllComments"
                  @handleSelectEmoji="handleSelectEmoji"
                />
              </div>
            </div>
          </template>
          <empty v-else :title="$t('LEADERS.NO_POSTS')" />

          <!-- post modal -->
          <add-post-modal :loading="addPosLoading" @handleAddPost="handleAddPost" />
        </div>

        <!-- followers -->
        <div v-if="selectedTab === 'followers'" a-tag="leaderFollowers">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div>
              <h6 class="top-follower">
                {{ leader.followers_count }}
                <span> {{ $t("LEADERS.FOLLOWERS") }}</span>
              </h6>
              <h6 class="monthly-change">
                {{ $t("LEADERS.LAST_THREE_DAYS") }}
                <span
                  :class="
                    handleStringStartsWith(leader.monthly_change) ? 'title-danger' : 'title-success'
                  "
                >
                  {{ leader.monthly_change }} %
                </span>
              </h6>
            </div>
            <!-- <TextField
              type="search"
              name="search"
              v-model.trim="search"
              @keyup="handleSearch"
              :placeholder="$t('GLOBAL_SEARCH')"
            /> -->
          </div>
          <followers :leaders="leader.followers.data" />
        </div>
      </div>

      <comment-modal
        :post="selectedPost"
        :loading="addCommentLoading"
        @handleAddComment="handleAddComment"
      />

      <!-- confirmation modal -->
      <confirmation-modal
        name="confirmation-modal"
        :title="confirmation.title"
        :message="confirmation.message"
        :subMessage="confirmation.subMessage"
        :confirmTitle="confirmation.confirmTitle"
        :showDangerImage="confirmation.showDangerImage"
        :isConfirmationLoading="isConfirmationLoading"
        @onConfirm="onConfirm"
        @onCancel="resetConfirmationModal"
      />
    </div>
  </div>
</template>

<script>
import { handleStringStartsWith, getDateFormat } from "@/helpers";

import {
  getLeaderRequest,
  postFollowRequest,
  postUnfollowRequest,
  postAddPostRequest,
  postAddCommentRequest,
  postReportLeaderRequest,
  postCommentReactionRequest,
} from "@/api/leaders";
import LeaderProfile from "@/components/Application/Leaders/LeaderProfile";
import PostCard from "@/components/Application/Leaders/PostCard";
import PostAndAllCommentsCard from "@/components/Application/Leaders/PostAndAllCommentsCard";
import AddPostModal from "@/components/Application/Leaders/AddPostModal";
import LineChart from "@/components/Application/Chart/LineChart";
import LeaderFollowModal from "@/components/Application/Leaders/LeaderFollowModal";
import LeaderReportModal from "@/components/Application/Leaders/LeaderReportModal";
import Followers from "@/components/Application/Leaders/Followers";
import CommentModal from "@/components/Application/Leaders/CommentModal";
import Empty from "@/components/Shared/Empty";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    LeaderProfile,
    PostCard,
    PostAndAllCommentsCard,
    AddPostModal,
    LineChart,
    Empty,
    LeaderFollowModal,
    LeaderReportModal,
    Followers,
    CommentModal,
  },
  data() {
    return {
      handleStringStartsWith,
      profileId: this.$route.params.id,
      loading: false,
      leader: null,
      followLoading: false,
      selectedTab: "portflio",
      addPosLoading: false,
      addCommentLoading: false,
      portflio: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
      selectedLeader: null,
      reportLoading: false,
      confirmation: {
        title: "",
        message: "",
        subMessage: "",
        confirmTitle: "",
        showDangerImage: true,
      },
      isConfirmationLoading: false,
      selectedPost: null,
      showAllComments: null,
    };
  },
  watch: {
    notification() {
      if (this.notification) {
        this.handleLoadLeader();
        // this.handleSetNotification(this.notification.type);
      }
    },
    $route() {
      if (this.$route.params) {
        this.profileId = this.$route.params.id;
        this.handleLoadLeader(true);
      }
      if (this.$route.query && this.$route.query.route) {
        this.handleSetNotification(this.$route.query.route);
      }
    },
  },
  created() {
    this.handleLoadLeader(true);
    if (this.$route.query && this.$route.query.route) {
      this.handleSetNotification(this.$route.query.route);
    }
  },
  computed: {
    ...mapGetters(["user", "token", "notification"]),
    tabs() {
      const tabs = [
        {
          name: this.$t("LEADERS.PORTFOLIO"),
          link: "portflio",
        },
        {
          name: this.$t("LEADERS.POSTS"),
          link: "posts",
        },
        {
          name: this.$t("LEADERS.HISTORY"),
          link: "history",
        },
      ];
      if (this.leader && this.user && this.user.id === this.leader.user.data.id) {
        tabs.push({
          name: this.$t("LEADERS.FOLLOWERS"),
          link: "followers",
        });
      }

      return tabs;
    },
  },
  methods: {
    ...mapActions(["SetUser", "ShowToast"]),

    handleLoadLeader(loading = false) {
      if (loading) {
        this.loading = true;
      }
      const params = {
        id: this.profileId,
        include: "followers",
      };
      this.ApiService(getLeaderRequest(params))
        .then(({ data }) => {
          this.leader = data.data;

          let chartLabels = [];
          let chartValue = [];
          if (this.leader.wallet) {
            const wallet = JSON.parse(this.leader.wallet.data);
            chartLabels = [...wallet.snapshotVos]?.map((chart) =>
              getDateFormat(new Date(chart.updateTime), "DD/MM")
            );
            chartValue = [...wallet.snapshotVos]?.map((chart) => chart.data.totalAssetOfBtc);
          }

          this.portflio = {
            labels: chartLabels,
            datasets: [
              {
                backgroundColor: "#EFDFFF80",
                data: chartValue,
                borderColor: "#532680",
                pointBackgroundColor: "#532680",
              },
            ],
          };
          if (this.showAllComments) {
            const post = this.leader.posts.data.find(
              (post) => this.showAllComments.post.id === post.id
            );
            this.showAllComments = {
              post,
              show: true,
            };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSelectedLeader(leader) {
      this.selectedLeader = leader;
      this.$bvModal.show("leader-follow-modal");
    },

    handleFollow(data) {
      if (!this.user.first_name) {
        this.$bvModal.show("complete-profile-modal");
        return;
      }

      const body = {
        id: this.selectedLeader.id,
        stop_following_value: data.followingValue,
        convert_assets_to: data.converAssetsTo,
      };
      this.followLoading = true;
      this.ApiService(postFollowRequest(body))
        .then(({ data }) => {
          this.handleCloseFollowModal();
          this.handleLoadLeader();
          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: data.meta.custom.message,
          });
          const params = {
            token: this.token,
          };
          this.SetUser(params);
        })
        .finally(() => {
          this.followLoading = false;
        });
    },
    handleUnfollow() {
      if (!this.user.first_name) {
        this.$bvModal.show("complete-profile-modal");
        return;
      }

      this.confirmation = {
        title: this.$t("LEADERS.UNFOLLOW"),
        message: this.$t("LEADERS.ARE_YOU_UNFOLLOW"),
        subMessage: this.leader.name,
        confirmTitle: this.$t("GLOBAL_YES"),
        showDangerImage: true,
      };
      this.$bvModal.show("confirmation-modal");
    },

    onConfirm() {
      const body = {
        id: this.leader.id,
      };
      this.isConfirmationLoading = true;
      this.ApiService(postUnfollowRequest(body))
        .then(({ data }) => {
          this.handleLoadLeader();
          const params = {
            token: this.token,
          };
          this.SetUser(params);
          this.resetConfirmationModal();
          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: data.meta.custom.message,
          });
        })
        .finally(() => {
          this.isConfirmationLoading = false;
        });
    },
    resetConfirmationModal() {
      this.$bvModal.hide("confirmation-modal");
      this.confirmation = {
        title: "",
        message: "",
        subMessage: "",
        confirmTitle: "",
        showDangerImage: true,
      };
      this.isConfirmationLoading = false;
    },

    handleCloseFollowModal() {
      this.$bvModal.hide("leader-follow-modal");
      this.selectedLeader = null;
    },

    handleSelectTab(tab) {
      this.selectedTab = tab;
      this.showAllComments = null;
    },
    handleOpenAddPostModal() {
      if (!this.user.first_name) {
        this.$bvModal.show("complete-profile-modal");
        return;
      }

      this.$bvModal.show("add-post-modal");
    },
    handleAddPost(formValue) {
      const body = {
        id: this.leader.id,
        body: formValue.post,
      };
      this.addPosLoading = true;
      this.ApiService(postAddPostRequest(body))
        .then(({ data }) => {
          this.handleLoadLeader();
          this.selectedTab = "posts";
          this.$bvModal.hide("add-post-modal");

          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: data.meta.custom.message,
          });
        })
        .finally(() => {
          this.addPosLoading = false;
        });
    },
    handleShowAllComments(data) {
      this.showAllComments = data;
    },
    handleOpenAddCommentModal(post) {
      this.selectedPost = post;
      this.$bvModal.show("add-comment-modal");
    },
    handleAddComment(comment) {
      if (!this.user.first_name) {
        this.$bvModal.show("complete-profile-modal");
        return;
      }
      const body = {
        leader_id: this.leader.id,
        post_id: this.selectedPost.id,
        body: comment,
      };
      this.addCommentLoading = true;
      this.ApiService(postAddCommentRequest(body))
        .then(({ data }) => {
          this.handleLoadLeader();
          this.selectedTab = "posts";
          this.selectedPost = null;
          this.$bvModal.hide("add-comment-modal");
          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: data.meta.custom.message,
          });
        })
        .finally(() => {
          this.addCommentLoading = false;
        });
    },
    handleSelectEmoji(body) {
      this.ApiService(postCommentReactionRequest(body)).then(({ data }) => {
        this.handleLoadLeader();
        this.ShowToast({
          title: this.$t("GLOBAL_SUCCESS"),
          type: "success",
          message: data.meta.custom.message,
        });
      });
    },
    handleOpenReportModal() {
      if (!this.user.first_name) {
        this.$bvModal.show("complete-profile-modal");
        return;
      }

      this.$bvModal.show("leader-report-modal");
    },
    handleReportLeader(reason) {
      const body = {
        id: this.leader.id,
        reason,
      };
      this.reportLoading = true;
      this.ApiService(postReportLeaderRequest(body))
        .then(({ data }) => {
          this.handleLoadLeader();
          this.$bvModal.hide("leader-report-modal");
          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: data.meta.custom.message,
          });
        })
        .finally(() => {
          this.reportLoading = false;
        });
    },
    handleSetNotification(type) {
      switch (type) {
        case "unfollow":
        case "follow":
        case "followers":
          this.selectedTab = "followers";
          break;
        case "posts":
        case "post":
        case "comment":
          this.selectedTab = "posts";
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
