<template>
  <div class="profile-container" v-if="leader">
    <div class="mr-3">
      <image-circle
        :defaultImage="leader.image"
        class="profile-image"
        :width="120"
        :height="120"
        :iconHeight="75"
        :iconWidth="75"
      />
    </div>
    <div class="profile-container-wrraper">
      <div class="profile-details">
        <div>
          <ul>
            <li class="name mr-2">{{ leader.name }}</li>
            <li class="followers mr-2">
              {{ leader.followers_count }}
              <span> {{ $t("LEADERS.FOLLOWERS") }} </span>
            </li>
          </ul>
        </div>
        <div class="profile-actions">
          <button
            v-if="user && leader.user && user.id !== leader.user.data.id"
            :title="$t('LEADERS.REPORT')"
            class="btn btn-text"
            @click="handleOpenReportModal"
          >
            <img class="flag" src="@/assets/images/flag.svg" alt="" />
          </button>
          <button @click="handleCopy" class="btn btn-text" :title="$t('GLOBAL_COPY')">
            <img class="arrow" src="@/assets/images/arrow-window.svg" alt="" />
          </button>
          <Button
            :loading="loading"
            color="outline"
            customClass="btn-follow"
            @click="handleFollowOrUnfollow()"
            v-if="user && !user.is_leader && user.exchanges.data.length"
            a-tag="buttonUnfollow"
          >
            {{ leader.followed_at ? $t("LEADERS.UNFOLLOW") : $t("LEADERS.FOLLOW") }}
          </Button>

          <Button
            v-else-if="user && !user.is_leader && !user.exchanges.data.length"
            color="outline"
            customClass="btn-primary"
            :title="$t('LEADERS.FOLLOW_LINK_EXCHANGE')"
            v-b-tooltip.hover="{ customClass: 'tooltip-primary' }"
            disabled
            a-tag="buttonFollow"
          >
            {{ $t("LEADERS.FOLLOW") }}
          </Button>
        </div>
      </div>
      <!-- <div class="profile-performance">
        <h4 class="mr-4">
          83.10 %
          <span> {{ $t("LEADERS.TOTLA_PERFORMANCE") }} </span>
        </h4>
        <h5>
          3 %
          <span>
            {{ $t("LEADERS.FOLLOWERS") }}
          </span>
        </h5>
      </div> -->
      <div class="profile-exchange">
        <div v-if="leader.exchange_logo" class="profile-exchange-header">
          <h3>{{ $t("LEADERS.EXCHANGE") }}</h3>
          <img class="exchange" src="@/assets/images/exchange.svg" alt="" />
        </div>
        <div v-if="leader.tips_address" class="profile-exchange-header">
          <h3>{{ $t("LEADERS.TIPS_ADDRESS") }}</h3>
          <div class="profile-links">
            <img
              v-if="leader.tips_network.toLowerCase() === 'bitcoin'"
              src="@/assets/images/bitcoin.svg"
              :title="leader.tips_network"
            />
            <img
              v-if="leader.tips_network.toLowerCase() === 'ethereum'"
              src="@/assets/images/ethereum.svg"
              :title="leader.tips_network"
            />
          </div>
        </div>
        <div v-if="leader.socials" class="profile-exchange-header">
          <h3>{{ $t("LEADERS.SOCIAL_MEDIA") }}</h3>
          <div class="profile-links">
            <a
              v-if="leader.socials.instagram"
              :href="`//${leader.socials.instagram}`"
              target="_blank"
              class="mr-4"
            >
              <img class="social-icon" src="@/assets/images/instagram.svg" alt="" />
            </a>
            <a
              v-if="leader.socials.twitter"
              :href="`//${leader.socials.twitter}`"
              target="_blank"
              class="mr-4"
            >
              <img class="social-icon" src="@/assets/images/twitter.svg" alt="" />
            </a>
            <a
              v-if="leader.socials.linkedin"
              :href="`//${leader.socials.linkedin}`"
              target="_blank"
              class="mr-4"
            >
              <img class="social-icon" src="@/assets/images/linkedin.svg" alt="" />
            </a>
            <a
              v-if="leader.socials.telegram"
              :href="`//${leader.socials.telegram}`"
              target="_blank"
              class="mr-4"
            >
              <img class="social-icon" src="@/assets/images/telegram.svg" alt="" />
            </a>
            <a
              v-if="leader.socials.facebook"
              :href="`//${leader.socials.facebook}`"
              target="_blank"
              class="mr-4"
            >
              <img class="social-icon" src="@/assets/images/facebook.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div class="profile-about">
        <h5>
          {{ `${$t("LEADERS.ABOUT")} ${leader.name}` }}
        </h5>
        <p>
          {{ leader.bio }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ImageCircle from "@/components/Shared/ImageCircle";

export default {
  props: {
    leader: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ImageCircle,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["ShowToast"]),

    handleFollowOrUnfollow() {
      if (!this.user.first_name) {
        this.$bvModal.show("complete-profile-modal");
        return;
      }

      if (this.leader.followed_at) {
        this.$emit("handleUnfollow", this.leader.id);
      } else {
        this.$emit("handleSelectedLeader", this.leader);
      }
    },
    handleOpenReportModal() {
      this.$emit("handleOpenReportModal");
    },
    handleCopy() {
      const cb = navigator.clipboard;
      this.ShowToast({
        title: this.$t("GLOBAL_SUCCESS"),
        type: "success",
        message: this.$t("MY_PROFILE.COPIED"),
      });
      const link = window.location.origin;
      const fullRoute = `${link}${this.$route.fullPath}`;
      return cb.writeText(fullRoute);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
