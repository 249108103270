<template>
  <b-modal id="add-post-modal" hide-footer size="lg" @hide="closeModal">
    <template v-slot:modal-header="{ close }">
      <h6 class="mb-0">
        {{ $t("LEADERS.ADD_POST") }}
      </h6>
      <b-icon class="close-modal-icon" icon="x" @click="close"></b-icon>
    </template>

    <div class="modal-body" :class="$i18n.locale == 'en' ? '' : 'rtl'">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)" a-tag="formAddPost">
          <b-form-group>
            <TextAreaField
              v-model="form.post"
              rows="6"
              rules="required"
              no-resize
              :name="$t('LEADERS.POST')"
            />
          </b-form-group>

          <b-form-group class="d-flex justify-content-end">
            <div class="d-inline-block mr-3">
              <Button
                type="button"
                color="light-dark"
                customClass="text-uppercase font-14"
                @click="$bvModal.hide('add-post-modal')"
              >
                {{ $t("GLOBAL_CANCEL") }}
              </Button>
            </div>
            <Button
              type="submit"
              color="primary"
              :loading="loading"
              customClass="text-uppercase font-14"
            >
              {{ $t("GLOBAL_ADD") }}
            </Button>
          </b-form-group>
        </b-form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        post: null,
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
      this.$refs.observer.reset();
      this.form.post = null;
    },
    onSubmit() {
      this.$emit("handleAddPost", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
